<template>
  <m-base-card-new>
    <template v-slot:header>
      <MBaseCardHeaderNew
        :title="project.title"
        :available-date="
          !$language
            ? 'Available Date : N/A'
            : 'প্রজেক্ট প্রকাশের তারিখ: ' + 'N/A'
        "
        :btn-name="!$language ? 'Assessment' : 'মূল্যায়ন করো'"
        :element_type="'projcet'"
        @header_element_3="seeAssessed"
      ></MBaseCardHeaderNew>
      <!-- <MBaseCardHeaderNew
        :title="project.title"
        :available-date="!$language ? 'Available Date : N/A' : 'প্রকাশের তারিখ : N/A'"
        :element_type="'none'"
      ></MBaseCardHeaderNew> -->
      <v-dialog v-model="previewAssess" width="70%">
        <m-project-assessment-list
          :all-submissions="submissions"
          @close="previewAssess = false"
        ></m-project-assessment-list>
      </v-dialog>
    </template>
    <template v-slot:middle-content>
      <MBaseCardMiddleContent
        :element_1="project.due_date"
        :element_2="submitted ? 'Submitted' : 'Not Submitted'"
        :element_3="project.total_score"
        :element_4="grade || 'Not Published'"
        :element_type="'projcet'"
      ></MBaseCardMiddleContent>
    </template>
    <template v-slot:footer>
      <MBaseCardFooter
        :element_1="!$language ? 'Details' : 'বিস্তারিত'"
        :element_2="!$language ? 'Submit' : 'সাবমিট করো'"
        @footer_element_1="$router.push(`projects/${project.object_id}`)"
        @footer_element_2="$emit('submit')"
      ></MBaseCardFooter>
      <v-dialog v-model="preview">
        <file-previewer
          v-if="preview"
          :preview-url="url"
          :download-url="url"
          :title="project.title"
          :is-video="project.is_video"
          :is-download="project.is_downloadable"
          @cancel="preview = false"
        ></file-previewer>
      </v-dialog>
    </template>
  </m-base-card-new>
</template>

<script>
import _ from "lodash";
import projectService from "#ef/project/services/ProjectService";
import MBaseCardNew from "~ef/components/MBaseCardNew.vue";
import MBaseCardHeaderNew from "~ef/components/MBaseCardHeaderNew.vue";
import MBaseCardMiddleContent from "~ef/components/MBaseCardMiddleContent.vue";
import MBaseCardFooter from "~ef/components/MBaseCardFooter.vue";
import FilePreviewer from "~ef/components/FilePreviewer";
import MProjectAssessmentList from "#ef/project/components/MProjectAssessmentList";

export default {
  name: "MProjectListItem",
  components: {
    MBaseCardNew,
    MBaseCardHeaderNew,
    MBaseCardMiddleContent,
    MBaseCardFooter,
    FilePreviewer,
    MProjectAssessmentList,
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      preview: false,
      url: undefined,
      previewAssess: false,
      submissions: undefined,
    };
  },
  computed: {
    dueDateOver() {
      const now = new Date();
      const date = now.toISOString().split("T")[0];

      return date > this.project.due_date;
    },
    submitted() {
      return this.project.submissions && this.project.submissions.length > 0;
    },
    upload_date() {
      if (this.submitted) {
        const last_submission = _.sortBy(this.project.submissions, [
          "upload_date",
        ]);
        return last_submission[last_submission.length - 1].upload_date;
      }
      return "Not Available";
    },
    grade() {
      if (this.submitted) {
        let grade = this.project.submissions[0].grade;
        return grade !== undefined ? grade : "Not published";
      }
      return "Not Submitted";
    },
  },
  methods: {
    async fetchURL() {
      try {
        this.url = await projectService.get(
          this.project.object_id,
          this.project.batch_id,
        );
      } catch (e) {
        this.preview = false;
      }
    },
    async seeAssessed() {
      this.submissions = await projectService.getSubmissions(
        this.project.object_id,
      );
      if (
        this.submissions.submissions.length == 0 &&
        !this.submissions.step_submissions
      ) {
        this.$root.$emit("alert", [
          undefined,
          !this.$language
            ? "You have no submissions"
            : "আপনি এখনো কোনো জমা দেন নি",
        ]);
      } else this.previewAssess = true;
    },
  },
};
</script>

<style lang="scss">
.m-project-list-item {
  font-family: Poppins, sans-serif !important;
  color: #3d3d3d !important;

  .v-card__text {
    color: #3d3d3d !important;
  }

  button {
    font-family: Poppins, sans-serif !important;
  }
}
</style>
