<template>
  <v-card class="mk-card-radius pa-4">
    <v-card-title class="title mk-highlight-text">{{
      !$language ? "Submit Your Work" : "আপনার ফাইল সাবমিট করুন"
    }}</v-card-title>
    <v-card-text>
      <v-form v-model="isValid" ref="project">
        <v-file-input
          v-model="formData.files"
          chips
          multiple
          :label="!$language ? 'Upload your files here' : 'ফাইল আপলোড করো'"
          :rules="rules"
          @keypress.enter.native.prevent
        ></v-file-input>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="$emit('cancel')">
        {{ !$language ? "Cancel" : "বাতিল করো" }}
      </v-btn>
      <v-btn
        color="primary"
        :loading="saving"
        @click="save"
      >
        {{ !$language ? "Upload" : "আপলোড করো" }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import _ from "lodash";
import { atLeastOne, maxFiles, totalSize } from "~ef/utils/validations";
import { FieldValidations } from "/global/utils/validations.js";

export default {
  name: "MCProjectUpload",
  props: {
    saving: Boolean,
  },
  mixins: [FieldValidations],
  data() {
    return {
      isValid: false,
      formData: {
        files: [],
      },
      rules: [
        (v) => this.minValue(v.length, 1, "Total files"),
        (v) => this.maxValue(v.length, 10, "Total files"),
        (v) => this.combinedFfileSizeLessThanOrEqual(v, 50 * 1024 * 1024),
      ],
    };
  },
  methods: {
    save() {
      this.$refs.project.validate();
      if(this.isValid) this.$emit("submit", _.omitBy(this.formData, _.isUndefined));
    },
  },
};
</script>
