<template>
  <v-card>
    <div class="d-flex justify-end">
      <v-btn icon @click="$emit('close')"><v-icon>close</v-icon></v-btn>
    </div>
    <v-card-title class="justify-center" style="margin-top: -20px;"
      >My Assessed Submissions</v-card-title
    >
    <v-card-text>
      <v-tabs v-model="currentTab" class="pl-10 py-10">
        <v-tab v-for="(i, j) in allSubmissions.step_submissions" :key="j"
          >Step {{ j + 1 }} submissions</v-tab
        >
        <v-tab>Overall submissions</v-tab>
      </v-tabs>
      <v-tabs-items v-model="currentTab">
        <v-tab-item v-for="(i, j) in allSubmissions.step_submissions" :key="j">
          <v-row v-for="(url, index) in i.url" :key="index">
            <v-col class="pl-14"
              >{{ index + 1 }}. {{ getFileName(url, true) }}</v-col
            >
            <v-col
              ><v-btn
                color="primary"
                class="px-12"
                depressed
                :disabled="i.assessed_url[index] === ''"
                @click="viewFile(i.object_id, 'step' + j + '-' + index)"
                >See Assessed</v-btn
              ></v-col
            >
          </v-row>
          <div style="height: 30px;"></div>
        </v-tab-item>
        <v-tab-item>
          <v-row
            v-for="(url, index) in allSubmissions.submissions[0].url"
            :key="index"
          >
            <v-col class="pl-14"
              >{{ index + 1 }}. {{ getFileName(url, false) }}</v-col
            >
            <v-col
              ><v-btn
                color="primary"
                class="px-12"
                depressed
                :disabled="
                  allSubmissions.submissions[0].assessed_url[index] === ''
                "
                @click="
                  viewFile(
                    allSubmissions.submissions[0].object_id,
                    'whole-' + index,
                  )
                "
                >See Assessed</v-btn
              ></v-col
            >
          </v-row>
          <div style="height: 30px;"></div>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "MProjectAssessmentList",
  props: { allSubmissions: Object },
  data() {
    return {
      currentTab: 0,
    };
  },
  methods: {
    getFileName(url, isStep) {
      let s3Name = decodeURIComponent(
        url
          .split("?")[0]
          .split("/")
          .pop(),
      );
      if (isStep === true) {
        let splitted = s3Name.split("_");
        splitted.shift();
        return splitted.join("_");
      }
      return s3Name;
    },
    viewFile(objectId, index) {
      this.$router.push({
        name: "assessment",
        params: { type: "project", index, object_id: objectId },
      });
    },
  },
};
</script>

<style></style>
