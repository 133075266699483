<template>
  <v-container>
    <PageTitleSearch
      :page-title="!$language ? 'Projects' : 'প্রজেক্ট'"
      :placeholder="
        !$language
          ? 'Search By Project Title'
          : 'প্রজেক্টের শিরোনাম দিয়ে সার্চ করো'
      "
      @searchElenent="searchProject"
    ></PageTitleSearch>
    <v-container>
      <v-row>
        <v-col v-if="fetchingContent">
          <template>
            <div id="loading-wrapper">
              <div id="loading-text">LOADING</div>
              <div id="loading-content"></div>
            </div>
          </template>
        </v-col>
        <v-col v-else-if="networkError">
          <no-content-component
            :message="networkErrorNoContentMessage"
          ></no-content-component>
        </v-col>
        <v-col v-else-if="noProjectCondition">
          <no-content-component
            :message="noProjectMessage"
          ></no-content-component>
        </v-col>
        <v-col
          v-else
          v-for="(project, i) in filterProjects"
          :key="i"
          :cols="colsNum"
        >
          <m-project-list-item
            :project="project"
            @submit="showUploadDialog(project.object_id)"
          ></m-project-list-item>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="upload_dialog_visible" width="500px">
      <m-project-upload
        :saving="saving"
        @cancel="hideUploadDialog"
        @submit="uploadProject"
      ></m-project-upload>
    </v-dialog>
  </v-container>
</template>

<script>
import batchService from "#ef/batch/services/BatchService";
import PageTitleSearch from "@ef/global/components/PageTitleSearch.vue";
import MProjectListItem from "#ef/project/components/MProjectListItem";
import MProjectUpload from "#ef/project/components/MProjectUpload";
import projectService from "#ef/project/services/ProjectService";
import { delayPromise } from "/global/utils/helpers";
import NoContentComponent from "/global/components/NoContentComponent";

export default {
  name: "ProjectListPage",
  components: {
    MProjectUpload,
    MProjectListItem,
    PageTitleSearch,
    NoContentComponent,
  },
  props: {
    batch_id: String,
  },
  data() {
    return {
      projects: [],
      upload_dialog_visible: false,
      saving: false,
      submission_object_id: undefined,
      searchInput: "",
      fetchingContent: undefined,
      networkError: false,
    };
  },
  computed: {
    filterProjects() {
      return this.projects.filter((project) =>
        project.title.toLowerCase().match(this.searchInput.toLowerCase())
      );
    },
    currentWidth() {
      return this.$vuetify.breakpoint.width;
    },
    colsNum() {
      return this.currentWidth < 800
        ? 12
        : this.currentWidth < 1280
        ? 12
        : this.currentWidth < 1920
        ? 6
        : 4;
    },
    noProjectCondition() {
      return this.projects === undefined || this.projects.length == 0;
    },
    noProjectMessage() {
      return !this.$language
        ? "You currently have no projects"
        : "বর্তমানে আপনার কোনো প্রজেক্ট সমূহ নেই";
    },
    networkErrorNoContentMessage() {
      return !this.$language
        ? "Course projects are currently not available"
        : "বর্তমানে কোর্সের প্রজেক্ট সংক্রান্ত তথ্য এনে দেয়া সম্ভব হচ্ছে না";
    },
  },
  created() {
    const isOnline = window.navigator.onLine;
    if (!isOnline) {
      this.$root.$emit("alert", [
        "Alert",
        "There might be an internet connection issue on your system",
      ]);
      this.networkError = true;
      this.fetchingContent = false;
      return;
    }
    this.fetchProjects();
  },
  methods: {
    searchProject(searchInput) {
      this.searchInput = searchInput;
    },
    async fetchProjects() {
      try {
        this.fetchingContent = true;
        this.projects = await projectService.find(this.batch_id);
        for (const a of this.projects) {
          const sub = await projectService.getSubmissions(a.object_id);
          this.$set(a, "submissions", sub.submissions);
        }
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.fetchingContent = false;
      }
    },
    async fetchBatches() {
      try {
        this.batches = await batchService.find(this.batch_id);
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      }
    },
    async uploadProject(projectData) {
      const isOnline = window.navigator.onLine;
      if (!isOnline) {
        this.$root.$emit("alert", [
          "Alert",
          "There might be an internet connection issue on your system",
        ]);
        return;
      }
      try {
        this.saving = true;
        projectData.object_id = this.submission_object_id;
        await projectService.save(this.batch_id, projectData);
        this.hideUploadDialog();
        await delayPromise(2000);
        await this.fetchProjects();
      } catch (e) {
        if (typeof e.message === "string") {
          let error_message = JSON.parse(e.message);
          if (error_message.error === "Unauthorized access") {
            this.$root.$emit("alert", [
              undefined,
              "The project you are trying to submit has already expired",
            ]);
          }
        } else {
          this.$root.$emit("alert", [undefined, e.message]);
        }
      } finally {
        this.saving = false;
      }
    },
    async download(project_id) {
      try {
        const url = await projectService.get(project_id, this.batch_id);
        window.open(url);
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      }
    },
    hideUploadDialog() {
      this.upload_dialog_visible = false;
    },
    showUploadDialog(object_id) {
      this.submission_object_id = object_id;
      this.upload_dialog_visible = true;
    },
    shareProject(idx) {
      this.projectToShare = this.projects[idx];
      this.shareprojectPanel = true;
    },
  },
};
</script>

<style scoped></style>
