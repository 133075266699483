var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c("PageTitleSearch", {
        attrs: {
          "page-title": !_vm.$language ? "Projects" : "প্রজেক্ট",
          placeholder: !_vm.$language
            ? "Search By Project Title"
            : "প্রজেক্টের শিরোনাম দিয়ে সার্চ করো",
        },
        on: { searchElenent: _vm.searchProject },
      }),
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _vm.fetchingContent
                ? _c(
                    "v-col",
                    [
                      [
                        _c("div", { attrs: { id: "loading-wrapper" } }, [
                          _c("div", { attrs: { id: "loading-text" } }, [
                            _vm._v("LOADING"),
                          ]),
                          _c("div", { attrs: { id: "loading-content" } }),
                        ]),
                      ],
                    ],
                    2
                  )
                : _vm.networkError
                ? _c(
                    "v-col",
                    [
                      _c("no-content-component", {
                        attrs: { message: _vm.networkErrorNoContentMessage },
                      }),
                    ],
                    1
                  )
                : _vm.noProjectCondition
                ? _c(
                    "v-col",
                    [
                      _c("no-content-component", {
                        attrs: { message: _vm.noProjectMessage },
                      }),
                    ],
                    1
                  )
                : _vm._l(_vm.filterProjects, function (project, i) {
                    return _c(
                      "v-col",
                      { key: i, attrs: { cols: _vm.colsNum } },
                      [
                        _c("m-project-list-item", {
                          attrs: { project: project },
                          on: {
                            submit: function ($event) {
                              return _vm.showUploadDialog(project.object_id)
                            },
                          },
                        }),
                      ],
                      1
                    )
                  }),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "500px" },
          model: {
            value: _vm.upload_dialog_visible,
            callback: function ($$v) {
              _vm.upload_dialog_visible = $$v
            },
            expression: "upload_dialog_visible",
          },
        },
        [
          _c("m-project-upload", {
            attrs: { saving: _vm.saving },
            on: { cancel: _vm.hideUploadDialog, submit: _vm.uploadProject },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }