import { globalData } from "root-src/main";

export function atLeastOne(v) {
  if (!globalData.$data.$language)
    return v.length > 0 || "At least 1 file is required";
  else return v.length > 0 || "কমপক্ষে ১টি ফাইল প্রয়োজন";
}

export function maxFiles(val) {
  return v => v.length <= val || "More than " + val + " files are not allowed";
}

export function totalSize(size) {
  return v =>
    v.reduce((acc, curr) => acc + curr.size, 0) <= size ||
    "Total size maximum " + size / 1024 / 1024 + " MB";
}
