import { Backend } from "~/utils/backend";
const $backend = new Backend(process.env.VUE_APP_BACKEND_EDU_STUDENTS_URL);
import { checkStatus } from "/global/utils/helpers";

export default {
  PROJECT_BATCH_URL: "projects/batches/",
  /**
   * @return Promise<AxiosResponse>
   * @param batch_id
   * @param opts
   */
  find(batch_id, opts = {}) {
    return $backend
      .get("students_get_shares", {
        params: { batch_id, object_type: "project" },
        ...opts,
      })
      .then(checkStatus)
      .then(r => r.data.content.stores);
  },
  get(object_id, batch_id, opts = {}) {
    const object_type = "project";
    return $backend
      .get("students_get_shares", {
        params: { object_id, batch_id, object_type },
        ...opts,
      })
      .then(checkStatus)
      .then(r => r.data.content.stores[0].url);
  },
  getProject(object_id, course_id, batch_id, opts = {}) {
    const object_type = "project";
    return $backend
      .get("students_get_project_details", {
        params: {
          course_id: course_id,
          batch_id: batch_id,
          object_type: object_type,
          object_id: object_id,
        },
        ...opts,
      })
      .then(checkStatus)
      .then(r => r.data.content.project);
  },
  /**
   * @return Promise
   * @param courseId
   * @param project
   * @param headers
   */
  async save(courseId, project, headers = {}) {
    const files = [...project.files];
    project.files = files.map(item => item.name);
    project.batch_id = courseId;
    project.object_type = "project";
    return $backend
      .post("students_post_submissions", project)
      .then(checkStatus)
      .then(({ data }) =>
        data.content.info.forEach((element, index) =>
          $backend.upload_to_s3(element, files[index]),
        ),
      );
  },

  stepSubmit(index, files, objectId, batchId) {
    return $backend
      .post("students_post_step_submissions", {
        step: index,
        files: files.map(f => f.name),
        object_id: objectId,
        batch_id: batchId,
      })
      .then(checkStatus)
      .then(({ data }) =>
        data.content.info.forEach((element, index) =>
          $backend.upload_to_s3(element, files[index]),
        ),
      );
  },

  share(project_id, data) {
    data["object_id"] = project_id;
    data["object_type"] = "project";
    return $backend
      .post("teachers_post_shares", data)
      .then(checkStatus)
      .then(r => r.data.content);
  },

  getSharedBatches(project_id) {
    return $backend
      .get(this.PROJECT_BATCH_URL, { params: { project_id } })
      .then(checkStatus)
      .then(r => r.data.data.shared_batches);
  },

  getSubmissions(object_id) {
    return $backend
      .get("students_get_submissions", {
        params: { object_id, object_type: "project" },
      })
      .then(checkStatus)
      .then(r => r.data.content);
  },
};
