// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".m-project-list-item {\n  font-family: Poppins, sans-serif !important;\n  color: #3d3d3d !important;\n}\n.m-project-list-item .v-card__text {\n  color: #3d3d3d !important;\n}\n.m-project-list-item button {\n  font-family: Poppins, sans-serif !important;\n}", ""]);
// Exports
exports.locals = {
	"primary": "#0099dc",
	"orange": "#FC6222",
	"decline": "#ff4848"
};
module.exports = exports;
